import {
  ZfIconButton,
  ZfSearchField,
  ZfTable,
  ZfTextButton,
} from "@ccx/zafire-react";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  EntityModelAuditDto,
  JobControllerService,
  PagedModelEntityModelJobLogDto,
  RelationshipManagerControllerService,
} from "../../openapi";
import { StoreSlices, useAppDispatch, useAppSelector } from "../../store/store";
import {
  reset,
  setSelectedViewRelManager,
} from "../../store/select-item-slice-impl/selected-item-slice-view-rel-manager";
import Spinner from "../common/Spinner";
import RenderModal from "./RenderModal";

const ViewRelationshipManager = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);
  const dataTable = useRef<HTMLZfTableElement>(null);
  //const [statuses, setStatuses] = useState<EntityModelStatusDto[]>();

  const statuses = useAppSelector((state) => state.comboBoxSlice.statuses);
  const dispatch = useAppDispatch();

  const [relManagerFetching, setRelManagerFetching] = useState<boolean>(false);

  const [lastImport, setLastImport] = useState<EntityModelAuditDto>();

  const [onClickAction, setOnClickAction] = useState<"update-rel-manager">();

  async function dataTableApiCall(url: any, config: any, params: any) {
    setRelManagerFetching(true);

    const sortArray: Array<string> =
      params.sort.length > 0
        ? [`${params.sort[0].field},${params.sort[0].dir}`]
        : [];

    let apiParams: Parameters<
      typeof RelationshipManagerControllerService.getAllRelationshipManagers
    >[0] = {
      page: params.page,
      size: params.size,
      sort: sortArray,
    };

    if (searchFilter.current?.value) {
      apiParams = { ...apiParams, search: searchFilter.current?.value };
    }

    return authorizedCall(
      RelationshipManagerControllerService.getAllRelationshipManagers,
      apiParams,
      true
    )
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        /*do nothing*/
      })
      .finally(() => {
        //setRelManagerFetching(false);
      });
  }

  const cellEditedHandler = useCallback(
    (cell: any, table: any) => {
      const data = cell.getData();
      const value = cell.getValue();
      const initValue = cell.getInitialValue();

      if (value === undefined || value.trim() === "") {
        cell.setValue(initValue);
      }

      if (statuses) {
        const statusId = statuses.filter(
          (s) => s.statusText === cell.getValue()
        )[0].statusId;

        setRelManagerFetching(true);
        authorizedCall(
          RelationshipManagerControllerService.updateRelationshipManager,
          {
            relationshipManagerPathId: data.id,
            requestBody: { status: statusId },
          }
        )
          .then((resp: any) => {
            console.log("statuses", resp);
            //setRelManagerFetching(false);
            //table.setPageSize(options.paginationSize);
            table.setData();
          })
          .catch((error) => {
            /*do nothing*/
          })
          .finally(() => {
            //setRelManagerFetching(false);
          });
      }
    },
    [statuses]
  );

  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    console.log("statuses", statuses);

    const TABLE_COLUMNS = [
      { title: "ID", field: "id", maxWidth: 120 },
      {
        title: "Number",
        field: "number",
        maxWidth: 120 /*, headerFilter: 'input'*/,
      },
      { title: "UserID", field: "upn", maxWidth: 80 },
      { title: "Name", field: "name" /*, headerFilter: 'input'*/ },
      { title: "TelNr", field: "phone" },
      { title: "Address", field: "address" },
      { title: "Org Unit", field: "orgUnit", maxWidth: 120 },
      {
        title: "Status",
        field: "statusText",
        editor: "list",
        editorParams: {
          values: statuses && statuses.map((elem: any) => elem.statusText),
        },
        formatter: (cell: any) => formatStatusCell(cell.getValue()),
        maxWidth: 100,
      },
      { title: "Created at", field: "createTs", maxWidth: 200 },
      { title: "Created by", field: "createUser", maxWidth: 120 },
    ];

    const actionColumns: [] = [];

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
    };

    getLastImportRm();
  }, [statuses]);

  function refreshTable(event: any) {
    dispatch(reset());
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  function getLastImportRm() {
    setRelManagerFetching(true);
    authorizedCall(
      JobControllerService.getLastJobRun,
      { jobName: "IMPORT_RM" },
      true
    )
      .then((resp: PagedModelEntityModelJobLogDto) => {
        console.log(resp);
        if (resp._embedded?.entities) setLastImport(resp._embedded.entities[0]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        //setAccFetching(false);
      });
  }

  const tableBuiltHandlerCallback = useCallback(
    (event: any) =>
      tableBuiltHandler(
        event,
        setRelManagerFetching,
        cellEditedHandler,
        StoreSlices.selectedViewRelManagerSlice,
        setSelectedViewRelManager
      ),
    [cellEditedHandler]
  );

  console.log(
    "RENDER - ViewRelationshipManager [relManagerFetching:",
    relManagerFetching,
    "]"
  );

  function formatStatusCell(cellValue: string) {
    const color =
      {
        ok: "green",
        arc: "green",
        chk: "yellow",
        del: "red",
        new: "blue",
      }[cellValue] || "neutral";

    return `<zf-pill type="solid" color=${color}>${cellValue}</zf-pill>`;
  }

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        {
          name: "View Relationship Manager",
          route: "/view-reéationship-manager",
        },
      ]}
    >
      <div className="content p-4 bg-white">
        <div style={{ borderBottom: "1px solid #ddd" }}>
          <div className="flex flex-row pb-6">
            <div className="basis-1/2">
              <p
                className="heading--two text-lg"
                data-testid="view-relationship-manager-pagetitle"
              >
                View Relationship Manager
              </p>
            </div>
            <div className="basis-1/4">
              <ZfSearchField
                placeholder="Search ..."
                onInputChange={(e) => {
                  refreshTable(e);
                }}
                ref={searchFilter}
                data-testid="view-rel-manager-search"
              ></ZfSearchField>
            </div>

            <div className="basis-1/4 flex flex-row-reverse">
              <ZfTextButton
                disabled={relManagerFetching === true}
                //onClick={updateAllRelationshipManager}
                onClick={() => setOnClickAction("update-rel-manager")}
                size="small"
              >
                Update
              </ZfTextButton>
              <ZfIconButton
                hierarchy="secondary"
                icon="arrow_back"
                className="mr-2"
                onClick={() => navigate(`/`)}
                size="small"
              ></ZfIconButton>
            </div>
          </div>
        </div>
        <div className="mt-4 mb-3 text-sm">
          <label className="font-semibold">Last import by:</label>
          <label className="font-light pl-2">{lastImport?.createUser}</label>
          <label className="font-semibold ml-2">at:</label>
          <label className="font-light pl-2">{lastImport?.createTs}</label>
        </div>
        {error && <p className="error">Error in loading data</p>}
        <RenderModal
          action={onClickAction}
          setOnClickAction={setOnClickAction}
          setComponentPanelFetching={setRelManagerFetching}
        />

        <Spinner fetching={relManagerFetching} />

        <ZfTable
          ref={dataTable}
          onTableBuilt={(event) => tableBuiltHandlerCallback(event)}
          // onTableRowSelectionChanged={rowSelectionChanged}
          className="mt-5"
          data-testid="vrm-data-table"
          style={{
            pointerEvents: relManagerFetching && "none",
            opacity: relManagerFetching && 0.7,
          }}
        ></ZfTable>
      </div>
    </AuthenticatedLayout>
  );
};

export default ViewRelationshipManager;
