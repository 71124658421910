import { EntityModelNostroOverviewDto } from "../../openapi";
import { createSelectedItemSlice } from "../selected-item-slice";

// Create specific slices for different types of selected items
const SelectedNostroItemSlice =
  createSelectedItemSlice<EntityModelNostroOverviewDto>("selectedNostroItem");

export const { setSelectedItem: setSelectedNostroItem, reset } =
  SelectedNostroItemSlice.actions;

export default SelectedNostroItemSlice.reducer;
