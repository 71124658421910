import { ZfSearchField, ZfTable } from "@ccx/zafire-react";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import React, { useCallback, useEffect, useRef, useState } from "react";
import authorizedCall from "../../utils/authorizedCallUtils";
import { NostroControllerService } from "../../openapi";
import { StoreSlices, useAppDispatch } from "../../store/store";
import Spinner from "../common/Spinner";
import {
  reset,
  setSelectedNostroNostroLimitLog,
} from "../../store/select-item-slice-impl/selected-item-slice-nostro-nos-limit-log";
import { importCellFormatter } from "../common/ZfTable/zftable-cell-editors";

export const AccountViewEditNostroLimits: React.FC<{
  nosId: number;
}> = (props) => {
  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);
  const dataTable = useRef<HTMLZfTableElement>(null);

  const [limitFetching, setLimitFetching] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  // useEffect to log all states on each change
  useEffect(() => {
    console.log("Component state snapshot:", {
      limitFetching,
      nosId: props.nosId,
    });
  }, [limitFetching, props.nosId]);

  useEffect(() => {
    const dataTableApiCall = async (url: any, config: any, params: any) => {
      console.log("NostroControllerService.getAllNostroLimitForNostro api");
      setLimitFetching(true);
      const sortArray: Array<string> =
        params.sort.length > 0
          ? [`${params.sort[0].field},${params.sort[0].dir}`]
          : [];
      let apiParams: Parameters<
        typeof NostroControllerService.getAllNostroLimitForNostro
      >[0] = {
        nostroPathId: props.nosId,
        page: params.page,
        size: params.size,
        sort: sortArray,
      };

      if (searchFilter.current?.value) {
        apiParams = { ...apiParams, search: searchFilter.current?.value };
      }

      return authorizedCall(
        NostroControllerService.getAllNostroLimitForNostro,
        apiParams,
        true
      )
        .then((resp) => {
          return resp;
        })
        .finally(() => {
          //setLimitFetching(false);
        });
    };

    const defaultOptions = zfTableDefaultOptions();

    const TABLE_COLUMNS = [
      { title: "ID", field: "id", maxWidth: 70 },
      { title: "Valid From", field: "validFrom", maxWidth: 150 },
      { title: "Valid To", field: "validTo", maxWidth: 150 },
      {
        title: "Val CHF",
        field: "valChf",
        headerHozAlign: "right",
        hozAlign: "right",
        formatter: importCellFormatter,
        editable: false,
        maxWidth: 200,
      },
      //{ title: "Remarks", field: "remark" },
      { title: "Create user", field: "createUser", maxWidth: 150 },
      { title: "Created at", field: "createTs", maxWidth: 150 },
    ];

    const actionColumns: [] = [];

    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
      selectable: 1,
    };
  }, [props.nosId, dispatch]);

  function refreshTable(event: any) {
    dispatch(reset());
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  const tableBuiltHandlerCallback = useCallback((event: any) => {
    tableBuiltHandler(
      event,
      setLimitFetching,
      undefined,
      StoreSlices.selectedNostroNostroLimitLogSlice,
      setSelectedNostroNostroLimitLog,
      undefined
    );
  }, []);

  /*console.log(
    "RENDER - AccountViewEditLimits [limitFetching:",
    limitFetching,
    "]"
  );*/

  return (
    <>
      <div className="flex flex-row-reverse">
        <div className="basis-1/4 flex"></div>
        <div className="basis-1/4 mr-12">
          <ZfSearchField
            placeholder="Search ..."
            onInputChange={(e) => {
              refreshTable(e);
            }}
            ref={searchFilter}
          ></ZfSearchField>
        </div>
      </div>

      <Spinner fetching={limitFetching} />

      <ZfTable
        ref={dataTable}
        onTableBuilt={tableBuiltHandlerCallback}
        //onTableRowSelected={rowSelectedCallBack}
        className="mt-5"
        data-testid="nos-lim-data-table"
        style={{
          pointerEvents: limitFetching && "none",
          opacity: limitFetching && 0.7,
        }}
      ></ZfTable>
    </>
  );
};
