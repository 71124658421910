import { useCallback, useEffect, useRef, useState } from "react";
import {
  ZfBanner,
  ZfIconButton,
  ZfLoadingSpinner,
  ZfSearchField,
  ZfTable,
  ZfTextButton,
} from "@ccx/zafire-react";
import { useNavigate } from "react-router-dom";
import {
  EntityModelNostroStagingDeleteDto,
  NostroControllerService,
} from "../../../../openapi";
import authorizedCall from "../../../../utils/authorizedCallUtils";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../../../../components/common/ZfTable/zftable-configuration";

const NostroToDelete = () => {
  const navigate = useNavigate();

  const dataTable = useRef<HTMLZfTableElement>(null);
  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);

  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const [selected, setSelected] = useState<number[]>([]);

  /**
   *
   * @param url the url of the request
   * @param config the ajaxConfig object
   * @param params the ajaxParams object
   * @returns
   */
  const dataTableApiCall = useCallback(
    async (url: any, config: any, params: any) => {
      setIsFetching(true);
      const sortArray: Array<string> =
        params.sort.length > 0
          ? [`${params.sort[0].field},${params.sort[0].dir}`]
          : [];
      let apiParams: Parameters<
        typeof NostroControllerService.getAllNostroToDelete
      >[0] = {
        page: params.page,
        size: params.size,
        sort: sortArray,
      };

      if (searchFilter.current?.value) {
        apiParams = { ...apiParams, search: searchFilter.current?.value };
      }

      try {
        return await authorizedCall(
          NostroControllerService.getAllNostroToDelete,
          apiParams,
          true
        );
      } catch (error) {
        return error;
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  /**
   *
   * First configuration after renderization
   */
  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      {
        formatter: "zfCheckboxRowSelection",
        titleFormatter: "zfCheckboxTitleRowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 50,
      },
      { title: "Number", field: "number" },
      { title: "Name", field: "name" },
      { title: "Rel Manager", field: "relationshipManager" },
      { title: "Status", field: "status" },
      { title: "Category", field: "nostroCategory" },
      { title: "Risk Fraud", field: "riskFraud" },
    ];
    const actionColumns: [] = [];

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
    };
  }, [dataTableApiCall]);

  function filteredSearch(event: CustomEvent) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  function rowSelectionChanged(rowSelectionChanged: CustomEvent) {
    console.log("rowSelectionChanged", rowSelectionChanged.detail);

    setSelected(
      rowSelectionChanged.detail.data.map(
        (elem: EntityModelNostroStagingDeleteDto) => elem.id
      )
    );
  }

  function deleteSelectedNostro() {
    setIsFetching(true);
    authorizedCall(NostroControllerService.deleteSelectedNostro, {
      requestBody: { nostroDeleteIdList: selected },
    })
      .then(() => {
        dataTable.current!.options = { ...dataTable.current!.options };
      })
      .catch((error) => {
        /*do nothing*/
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  //console.log("RENDER - NostroToDelete [isFetching:", isFetching, "]");

  return (
    <>
      <div className="mb-4 mt-4">
        <ZfBanner type="info">
          There are Nostro missing after import procedure, you can update their
          statuses to delete
        </ZfBanner>
      </div>
      <div className="content p-4 bg-white">
        <div
          className="flex flex-row pb-6"
          style={{ borderBottom: "1px solid #ddd" }}
        >
          <div className="basis-1/2">
            <p
              className="heading--two text-xl"
              data-testid="view-acc-cat-pagetitle"
            >
              Nostro to delete
            </p>
          </div>
          <div className="basis-1/4">
            <ZfSearchField
              placeholder="Search ..."
              onInputChange={filteredSearch}
              ref={searchFilter}
            ></ZfSearchField>
          </div>
          <div className="basis-1/4"></div>
          <div className="basis-1/4 flex flex-row-reverse">
            <ZfTextButton
              hierarchy="primary"
              disabled={!selected.length || isFetching}
              onClick={(_) => deleteSelectedNostro()}
              className="mr-2"
              size="small"
            >
              Detete Selected
            </ZfTextButton>
            <ZfIconButton
              hierarchy="secondary"
              icon="arrow_back"
              className="mr-1"
              onClick={() => navigate(`/`)}
              size="small"
            ></ZfIconButton>
          </div>
        </div>
        {isFetching && (
          <ZfLoadingSpinner
            size="large"
            color="primary"
            type="infinite"
            style={{
              position: "absolute",
              left: "50%",
              right: "50%",
              zIndex: "100",
              top: "35%",
            }}
            data-testid="spinner"
          ></ZfLoadingSpinner>
        )}
        {error && <p className="error">Error in loading data</p>}

        <ZfTable
          ref={dataTable}
          onTableBuilt={(event) => tableBuiltHandler(event, setIsFetching)}
          onTableRowSelectionChanged={rowSelectionChanged}
          //hidden={type === "WITH_BALANCE" && datepicker === ""}
          className="mt-5"
          style={{
            pointerEvents: isFetching && "none",
            opacity: isFetching && 0.7,
          }}
          data-testid="nos-to-delete-table"
        ></ZfTable>
      </div>
    </>
  );
};

export default NostroToDelete;
