import { useCallback, useEffect, useRef, useState } from "react";
import {
  ZfBanner,
  ZfIconButton,
  ZfLoadingSpinner,
  ZfSearchField,
  ZfTable,
  ZfTextButton,
} from "@ccx/zafire-react";
import { useNavigate } from "react-router-dom";
import { CellComponent } from "@ccx/zafire/dist/types/zf-table-type";
import {
  EntityModelRMStagingUpdateDto,
  EntityModelStatusDto,
  RelationshipManagerControllerService,
  RMUpdateDto,
} from "../../../../openapi";
import authorizedCall from "../../../../utils/authorizedCallUtils";
import { useAppSelector } from "../../../../store/store";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../../../../components/common/ZfTable/zftable-configuration";
import {
  actionFormatter,
  customChecboxFormatter,
} from "../../../../components/common/ZfTable/zftable-cell-editors";

const RMToUpdate = () => {
  const navigate = useNavigate();

  const dataTable = useRef<HTMLZfTableElement>(null);
  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);

  const statuses = useAppSelector((state) => state.comboBoxSlice.statuses);

  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const [selected, setSelected] = useState<RMUpdateDto[]>([]);

  /**
   *
   * @param url the url of the request
   * @param config the ajaxConfig object
   * @param params the ajaxParams object
   * @returns
   */
  const dataTableApiCall = useCallback(
    async (url: any, config: any, params: any) => {
      setIsFetching(true);
      const sortArray: Array<string> =
        params.sort.length > 0
          ? [`${params.sort[0].field},${params.sort[0].dir}`]
          : [];
      let apiParams: Parameters<
        typeof RelationshipManagerControllerService.getAllRelationshipManagerToUpdate
      >[0] = {
        page: params.page,
        size: params.size,
        sort: sortArray,
      };

      if (searchFilter.current?.value) {
        apiParams = { ...apiParams, search: searchFilter.current?.value };
      }

      try {
        try {
          const resp = await authorizedCall(
            RelationshipManagerControllerService.getAllRelationshipManagerToUpdate,
            apiParams,
            true
          );
          if (resp._embedded?.entities) {
            resp._embedded.entities = resp._embedded?.entities.map(
              (elem: EntityModelRMStagingUpdateDto): RMUpdateDto => {
                return {
                  ...elem,
                  upnUpdate: elem.upn !== elem.stagedUpn,
                  nameUpdate: elem.name !== elem.stagedName,
                  phoneUpdate: elem.phone !== elem.stagedPhone,
                  orgUnitUpdate: elem.orgUnit !== elem.stagedOrgUnit,
                  addressUpdate: elem.address !== elem.stagedAddress,
                  newStatusId: elem.statusId,
                };
              }
            );
          }
          return resp;
        } catch (error) {
          return error;
        }
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  /**
   *
   * First configuration after renderization
   */
  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      {
        formatter: "zfCheckboxRowSelection",
        titleFormatter: "zfCheckboxTitleRowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 50,
      },
      { title: "Number", field: "number" },
      { title: "Upn", field: "upn" },
      { title: "Staged Upn", field: "stagedUpn", editor: "input" },
      {
        titleFormatter: actionFormatter,
        field: "upnUpdate",
        formatter: customChecboxFormatter,
        headerSort: false,
        width: 50,
      },
      { title: "Name", field: "name" },
      { title: "Staged Name", field: "stagedName", editor: "input" },
      {
        titleFormatter: actionFormatter,
        field: "nameUpdate",
        formatter: customChecboxFormatter,
        headerSort: false,
        width: 50,
      },
      { title: "Phone", field: "phone" },
      { title: "Staged Phone", field: "stagedPhone", editor: "input" },
      {
        titleFormatter: actionFormatter,
        field: "phoneUpdate",
        formatter: customChecboxFormatter,
        headerSort: false,
        width: 50,
      },
      { title: "OrgUnit", field: "orgUnit" },
      { title: "Staged OrgUnit", field: "stagedOrgUnit", editor: "input" },
      {
        titleFormatter: actionFormatter,
        field: "orgUnitUpdate",
        formatter: customChecboxFormatter,
        headerSort: false,
        width: 50,
      },
      { title: "Address", field: "address" },
      { title: "Staged Address", field: "stagedAddress", editor: "input" },
      {
        titleFormatter: actionFormatter,
        field: "addressUpdate",
        formatter: customChecboxFormatter,
        headerSort: false,
        width: 50,
      },
      {
        title: "Status",
        field: "statusId",
        formatter: (cell: any) => {
          let status =
            statuses &&
            statuses.find(
              (elem: EntityModelStatusDto) => elem.statusId === cell.getValue()
            )?.statusText;
          console.log("status", status);
          return status;
        },
      },
      {
        title: "New Status",
        field: "newStatus",
        editor: "list",
        editorParams: {
          values:
            statuses &&
            statuses.map((elem: EntityModelStatusDto) => elem.statusText),
        },
      },
    ];
    const actionColumns: [] = [];

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
    };
  }, [dataTableApiCall, statuses]);

  function filteredSearch(event: CustomEvent) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  function rowSelectionChanged(rowSelectionChanged: CustomEvent) {
    console.log("rowSelectionChanged", rowSelectionChanged.detail);

    setSelected(rowSelectionChanged.detail.data);
  }

  async function cellEditedHandler(cell: CellComponent, table: any) {
    const data = cell.getData() as RMUpdateDto;
    const value = cell.getValue();
    console.log("cell data", data, value);

    if (statuses && cell.getColumn().getField() === "newStatus")
      await cell.getRow().update({
        newStatusId: statuses.filter((s) => s.statusText === value)[0].statusId,
      });

    cell.getRow().select();
  }

  function updateSelectedRelationshipManager() {
    setIsFetching(true);
    authorizedCall(
      RelationshipManagerControllerService.updateSelectedRelationshipManager,
      {
        requestBody: {
          rmUpdateDtoList: selected,
        },
      }
    )
      .then(() => {
        dataTable.current!.options = { ...dataTable.current!.options };
      })
      .catch((error) => {
        /*do nothing*/
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  console.log("RENDER - RMToUpdate [isFetching:", isFetching, "]");

  return (
    <>
      <div className="mb-4 mt-4">
        <ZfBanner type="info">
          There are changed Relationship Manager after import procedure, please
          chose which field to update
        </ZfBanner>
      </div>
      <div className="content p-4 bg-white">
        <div
          className="flex flex-row pb-6"
          style={{ borderBottom: "1px solid #ddd" }}
        >
          <div className="basis-1/2">
            <p
              className="heading--two text-xl"
              data-testid="view-acc-cat-pagetitle"
            >
              Relationship Manager to update
            </p>
          </div>
          <div className="basis-1/4">
            <ZfSearchField
              placeholder="Search ..."
              onInputChange={filteredSearch}
              ref={searchFilter}
            ></ZfSearchField>
          </div>
          <div className="basis-1/4"></div>
          <div className="basis-1/4 flex flex-row-reverse">
            <ZfTextButton
              hierarchy="primary"
              disabled={!selected.length || isFetching}
              onClick={(_) => updateSelectedRelationshipManager()}
              className="mr-2"
              size="small"
            >
              Update Selected
            </ZfTextButton>
            <ZfIconButton
              hierarchy="secondary"
              icon="arrow_back"
              className="mr-1"
              onClick={() => navigate(`/`)}
              size="small"
            ></ZfIconButton>
          </div>
        </div>
        {isFetching && (
          <ZfLoadingSpinner
            size="large"
            color="primary"
            type="infinite"
            style={{
              position: "absolute",
              left: "50%",
              right: "50%",
              zIndex: "100",
              top: "35%",
            }}
            data-testid="spinner"
          ></ZfLoadingSpinner>
        )}
        {error && <p className="error">Error in loading data</p>}

        <ZfTable
          ref={dataTable}
          onTableBuilt={(event) =>
            tableBuiltHandler(event, setIsFetching, cellEditedHandler)
          }
          onTableRowSelectionChanged={rowSelectionChanged}
          //hidden={type === "WITH_BALANCE" && datepicker === ""}
          className="mt-5"
          style={{
            pointerEvents: isFetching && "none",
            opacity: isFetching && 0.7,
          }}
          data-testid="rm-to-update-table"
        ></ZfTable>
      </div>
    </>
  );
};

export default RMToUpdate;
