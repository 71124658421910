import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import NostroToUpdate from "../../features/staging/components/NostroToUpdate/NostroToUpdate";
import RMToUpdate from "../../features/staging/components/RMToUpdate/RMToUpdate";
import NostroToDelete from "../../features/staging/components/NostroToDelete/NostroToDelete";

const StagingUpdate = () => {
  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        {
          name: "Staging Update",
          route: "/stg-upd",
        },
      ]}
    >
      <NostroToUpdate />
      <NostroToDelete />
      <RMToUpdate />
    </AuthenticatedLayout>
  );
};

export default StagingUpdate;
