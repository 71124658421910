import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  EntityModelAccountDto,
  NostroControllerService,
  PagedModelEntityModelAccountDto,
} from "../../openapi";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import {
  ZfInputField,
  ZfSelectField,
  ZfSelectOption,
  ZfTextButton,
} from "@ccx/zafire-react";
import { AppError, appStateActions } from "../../store/appstate-slice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import Spinner from "../common/Spinner";
import {
  formatCHF,
  parseInputImportValue,
} from "../common/ZfTable/zftable-cell-editors";

interface FormInput {
  value: any;
  textError?: string;
  state?: "invalid" | "readonly";
}

interface FormData {
  id: FormInput;
  nostroNumber: FormInput;
  accountNumber: FormInput;
  createUser: FormInput;
  createTs: FormInput;
  statusId: FormInput;
  currency: FormInput;
  kstaValueCcyLimitLow: FormInput;
  kstaValueCcyLimitHigh: FormInput;
  busaldoValueCcyLimitLow: FormInput;
  busaldoValueCcyLimitHigh: FormInput;
  remark: FormInput;
  [key: string]: FormInput["value"];
}

function initFormData(): FormData {
  return {
    id: { value: 0 },
    nostroNumber: { value: "" },
    accountNumber: { value: "" },
    createUser: { value: "" },
    createTs: { value: "" },
    statusId: { value: "" },
    currency: { value: "" },
    kstaValueCcyLimitLow: { value: formatCHF(-0) },
    kstaValueCcyLimitHigh: { value: formatCHF(-0) },
    busaldoValueCcyLimitLow: { value: formatCHF(-0) },
    busaldoValueCcyLimitHigh: { value: formatCHF(-0) },
    remark: { value: "" },
    link: { value: "" },
  };
}

export const AccountLimitEdit = () => {
  const navigate = useNavigate();

  const { nosId } = useParams();

  const [accLimitFetching, setAccLimitFetching] = useState(false);

  const [formData, setFormData] = useState<FormData>(initFormData);

  const dispatch = useAppDispatch();

  const [nostroAccounts, setNostroAccounts] =
    useState<Array<EntityModelAccountDto>>();

  const statusComboBox = useAppSelector(
    (state) => state.comboBoxSlice.statuses
  );

  useEffect(() => {
    //const getAccounts =
    async function getAllNostroAccounts(): Promise<
      Array<EntityModelAccountDto>
    > {
      const apiParams = {
        nostroPathId: Number(nosId!),
        pageable: {
          page: 0,
          size: 1000,
        },
      };

      return authorizedCall(
        NostroControllerService.getAllNostroAccounts,
        apiParams,
        true
      ).then((dto: PagedModelEntityModelAccountDto) => {
        if (dto._embedded === undefined) {
          dispatch(
            appStateActions.error("No account available for this nostro.")
          );
          return new Array<EntityModelAccountDto>();
        }
        return dto._embedded!.entities!;
      });
    }

    getAllNostroAccounts().then((list) => {
      setNostroAccounts(list);
    });
  }, [dispatch, nosId]);

  function updateformDataImpl(field: string, subfield: string, value: any) {
    setFormData((prev: FormData) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [subfield]: value,
      },
    }));
  }

  function updateformDataValue(event: any) {
    const { name, value } = event.target;

    /*let fixedValue = value;
    if (event.currentTarget.type === "number") {
      fixedValue = Number(value.replaceAll(",", ""));
    }*/

    // if (name === "accountNumber" || name === "remark") {
    //   return updateformDataImpl(name, "value", value);
    // }

    // const parsed = parseInputImportValue(value);

    //const formatted = formatCHF(parsed);

    updateformDataImpl(name, "value", value);
  }

  function focusOnInputHandler(event: any) {
    const { name } = event.target;

    updateformDataImpl(name, "textError", undefined);
    updateformDataImpl(name, "state", undefined);
  }

  function validate(): boolean {
    let valid: boolean = true;

    if (!formData.accountNumber || formData.accountNumber.value === "") {
      updateformDataImpl("accountNumber", "textError", "Required field");
      updateformDataImpl("accountNumber", "state", "invalid");
      valid = false;
    }

    if (
      !formData.kstaValueCcyLimitLow ||
      formData.kstaValueCcyLimitLow.value === ""
      //|| formData.kstaValueCcyLimitLow.value === "0.00"
    ) {
      updateformDataImpl("kstaValueCcyLimitLow", "textError", "Required field");
      updateformDataImpl("kstaValueCcyLimitLow", "state", "invalid");
      valid = false;
    }

    if (
      !formData.kstaValueCcyLimitHigh ||
      formData.kstaValueCcyLimitHigh.value === ""
      //|| formData.kstaValueCcyLimitHigh.value === "0.00"
    ) {
      updateformDataImpl(
        "kstaValueCcyLimitHigh",
        "textError",
        "Required field"
      );
      updateformDataImpl("kstaValueCcyLimitHigh", "state", "invalid");
      valid = false;
    }

    if (
      !formData.busaldoValueCcyLimitLow ||
      formData.busaldoValueCcyLimitLow.value === ""
      //|| formData.busaldoValueCcyLimitLow.value === "0.00"
    ) {
      updateformDataImpl(
        "busaldoValueCcyLimitLow",
        "textError",
        "Required field"
      );
      updateformDataImpl("busaldoValueCcyLimitLow", "state", "invalid");
      valid = false;
    }

    if (
      !formData.busaldoValueCcyLimitHigh ||
      formData.busaldoValueCcyLimitHigh.value === ""
      //|| formData.busaldoValueCcyLimitHigh.value === "0.00"
    ) {
      updateformDataImpl(
        "busaldoValueCcyLimitHigh",
        "textError",
        "Required field"
      );
      updateformDataImpl("busaldoValueCcyLimitHigh", "state", "invalid");
      valid = false;
    }

    if (!formData.statusId || formData.statusId.value === "") {
      updateformDataImpl("statusId", "textError", "Required field");
      updateformDataImpl("statusId", "state", "invalid");
      valid = false;
    }

    return valid;
  }

  function submitHandler() {
    if (!validate()) {
      console.log("Validation fails");
      return;
    }

    setAccLimitFetching(true);
    console.log(nosId);

    const promise = authorizedCall(NostroControllerService.createAccountLimit, {
      nostroPathId: Number(nosId),
      requestBody: {
        accountId: formData.accountNumber.value,
        kstaValueCcyLimitLow: parseInputImportValue(
          formData.kstaValueCcyLimitLow.value
        ),
        kstaValueCcyLimitHigh: parseInputImportValue(
          formData.kstaValueCcyLimitHigh.value
        ),
        busaldoValueCcyLimitLow: parseInputImportValue(
          formData.busaldoValueCcyLimitLow.value
        ),
        busaldoValueCcyLimitHigh: parseInputImportValue(
          formData.busaldoValueCcyLimitHigh.value
        ),
        remark: formData.remark.value,
        status: formData.statusId.value,
      },
    });

    promise
      .then(() => {
        navigate(`/acc-view/${nosId}`);
      })
      .catch((error) => {
        const err: AppError = {
          code: error.status,
          message: error.error?.toString(),
        };
        dispatch(appStateActions.error(err));
      })
      .finally(() => {
        setAccLimitFetching(false);
      });
  }

  // const inputCurrenctyDigits = (event: any) => {
  //   const allowedKeys = [
  //     "Backspace",
  //     "Delete",
  //     "ArrowLeft",
  //     "ArrowRight",
  //     "Home",
  //     "End",
  //     ".",
  //     "'",
  //     ",",
  //   ];

  //   if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key)) {
  //     event.preventDefault();
  //   }

  //   const input = event.target;
  //   if (event.key === "." && input.value.includes(".")) {
  //     event.preventDefault();
  //   }
  // };

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        { name: "View Nostro", route: "/acc-view" },
        { name: "Nostro Detail", route: `/acc-view/${nosId}` },
        { name: "Account Limit" },
      ]}
    >
      <div className="content p-4 bg-white">
        <div className="flex flex-row">
          <div className="basis-1/2">
            <p
              className="heading--three text-xl"
              data-testid="acc-cat-pagetitle"
            >
              New Limit
            </p>
          </div>
          <div className="basis-1/2 text-right">
            <ZfTextButton
              hierarchy="tertiary"
              onClick={() => navigate(`/acc-view/${nosId}`)}
              size="small"
              data-testid="acc-lim-cancel"
            >
              Cancel
            </ZfTextButton>
            <ZfTextButton
              onClick={submitHandler}
              data-testid="acc-save-btn"
              size="small"
            >
              Save
            </ZfTextButton>
          </div>
        </div>

        <Spinner fetching={accLimitFetching} />

        {!accLimitFetching && (
          <>
            <div className="mt-4" data-testid="limit-edit">
              <div className="grid grid-cols-4 gap-4">
                <ZfSelectField
                  name="accountNumber"
                  label="Account Number"
                  value={String(formData?.accountNumber.value)}
                  errorText={formData.accountNumber.textError}
                  state={formData.accountNumber.state}
                  onFocus={focusOnInputHandler}
                  onSelectFieldChange={updateformDataValue}
                  defaultValue={0}
                  data-testid="acc-lim-accnum"
                >
                  {nostroAccounts?.map((acc) => (
                    <ZfSelectOption value={String(acc.id)} key={acc.id}>
                      {acc.number}
                    </ZfSelectOption>
                  ))}
                </ZfSelectField>

                <ZfInputField
                  label="KSTA VAL Low limit"
                  type="number"
                  name="kstaValueCcyLimitLow"
                  value={formData?.kstaValueCcyLimitLow.value}
                  defaultValue={0}
                  placeholder=""
                  errorText={formData.kstaValueCcyLimitLow.textError}
                  state={formData.kstaValueCcyLimitLow.state}
                  onFocus={focusOnInputHandler}
                  onBlur={updateformDataValue}
                  //onKeyDown={inputCurrenctyDigits}
                  data-testid="acc-lim-ksta-low"
                ></ZfInputField>

                <ZfInputField
                  label="KSTA VAL High limit"
                  type="number"
                  name="kstaValueCcyLimitHigh"
                  value={formData?.kstaValueCcyLimitHigh.value}
                  defaultValue={0}
                  placeholder=""
                  errorText={formData.kstaValueCcyLimitHigh.textError}
                  state={formData.kstaValueCcyLimitHigh.state}
                  onFocus={focusOnInputHandler}
                  onBlur={updateformDataValue}
                  //onKeyDown={inputCurrenctyDigits}
                  data-testid="acc-lim-ksta-high"
                ></ZfInputField>

                <ZfInputField
                  label="BuSaldo VAL Low Limit"
                  type="number"
                  name="busaldoValueCcyLimitLow"
                  value={formData?.busaldoValueCcyLimitLow.value}
                  defaultValue={0}
                  placeholder=""
                  errorText={formData.busaldoValueCcyLimitLow.textError}
                  state={formData.busaldoValueCcyLimitLow.state}
                  onFocus={focusOnInputHandler}
                  onBlur={updateformDataValue}
                  //onKeyDown={inputCurrenctyDigits}
                  data-testid="acc-lim-busaldo-low"
                ></ZfInputField>

                <ZfInputField
                  label="BuSaldo VAL High Limit"
                  type="number"
                  name="busaldoValueCcyLimitHigh"
                  value={formData?.busaldoValueCcyLimitHigh.value}
                  defaultValue={0}
                  placeholder=""
                  errorText={formData.busaldoValueCcyLimitHigh.textError}
                  state={formData.busaldoValueCcyLimitHigh.state}
                  onFocus={focusOnInputHandler}
                  onBlur={updateformDataValue}
                  //onKeyDown={inputCurrenctyDigits}
                  data-testid="acc-lim-busaldo-high"
                ></ZfInputField>

                <ZfInputField
                  label="Remark"
                  type="text"
                  name="remark"
                  value={formData?.remark.value}
                  placeholder=""
                  errorText={formData.remark.textError}
                  state={formData.remark.state}
                  onBlur={updateformDataValue}
                  data-testid="acc-lim-remark"
                ></ZfInputField>

                <ZfSelectField
                  name="statusId"
                  label="Status"
                  value={String(formData?.statusId.value)}
                  errorText={formData.statusId.textError}
                  state={formData.statusId.state}
                  onFocus={focusOnInputHandler}
                  onSelectFieldChange={updateformDataValue}
                  defaultValue={0}
                  data-testid="acc-lim-statusId"
                >
                  {statusComboBox?.map((status) => (
                    <ZfSelectOption
                      value={String(status.statusId)}
                      key={status.statusId}
                    >
                      {status.statusText}
                    </ZfSelectOption>
                  ))}
                </ZfSelectField>
              </div>
            </div>
          </>
        )}
      </div>
    </AuthenticatedLayout>
  );
};
