import { ZfDivider, ZfLoadingSpinner, ZfTextButton } from "@ccx/zafire-react";
import { Paper } from "../../../../components/common/components/Paper";
import { useNavigate } from "react-router-dom";
import {
  AppControllerService,
  EntityModelComponentPropertiesDto,
} from "../../../../openapi";
import authorizedCall from "../../../../utils/authorizedCallUtils";
import { useCallback, useEffect, useState } from "react";
import { ComponentProperty } from "../../../../mocks/mock-data";
import { useAppDispatch } from "../../../../store/store";
import RenderModal from "../../../../components/pages/RenderModal";
import JobExecutionPanel from "../../../../components/pages/JobExecutionPanel";

export const UserPanel = () => {
  const navigate = useNavigate();
  const [dbContentPanel, setDbContentPanel] = useState<JSX.Element[]>([]);
  const [componentPanelFetching, setComponentPanelFetching] = useState(false);
  const [onClickAction, setOnClickAction] = useState<
    "import-account-balance" | "export-nos-vs-acc-bal" | "export-nos-lim"
  >();
  const dispatch = useAppDispatch();

  const createUserPanel = useCallback(
    function createUserPanel(entities: EntityModelComponentPropertiesDto[]) {
      let userPanelMap: Map<string, ComponentProperty[]> = new Map();

      entities
        .map((c) => JSON.parse(c.properties))
        .sort((a, b) => {
          return a.order - b.order;
        })
        .forEach((component: ComponentProperty) => {
          let columnName = component.column;
          if (!columnName) throw Error("Found component without column name.");

          let label = component.label;
          if (!label) throw Error("Found component without label text.");

          if (!userPanelMap.has(columnName)) {
            const list: ComponentProperty[] = [component];
            userPanelMap.set(columnName, list);
          } else {
            const list = userPanelMap.get(columnName);
            if (
              list?.find((c) => c.componentId === component.componentId) ===
              undefined
            ) {
              list?.push(component);
            }
          }
        });
      //now map is sorted!
      let resp: any[] = [];

      userPanelMap.forEach((value, key) => {
        resp.push(
          <div className="lgrid-item" key={key}>
            <div
              className="heading"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "var(--color-action-primary-80)",
              }}
            >
              <div
                style={{
                  color: "white",
                  padding: "6px",
                }}
                className="heading--five text-sm font-thin"
              >
                {key}
              </div>
            </div>
            {value.map((component) => {
              const componentRoute = component.componentId;
              return (
                <ZfTextButton
                  style={{
                    width: "90%",
                    alignSelf: "center",
                    margin: "10px 0",
                  }}
                  key={component.componentId!}
                  hierarchy="secondary"
                  onClick={() => {
                    if (component.openDialog) {
                      setOnClickAction(component.openDialog);
                    } else {
                      navigate(`/${componentRoute}`);
                    }
                  }}
                  size="medium"
                >
                  {component.label}
                </ZfTextButton>
              );
            })}
          </div>
        );
      });

      return resp;
    },
    [navigate]
  );

  useEffect(() => {
    setComponentPanelFetching(true);

    const getGrants = async () => {
      const promise = authorizedCall(
        AppControllerService.getComponentProperties,
        {}
      );
      return promise
        .then((resp: any) => {
          console.log("Grant response:", resp);
          if (!resp) throw new Error();
          return createUserPanel(resp);
        })
        .catch((error) => {
          console.info(error);
          /*setComponentPanelFetching(false);
        if ((error.status && error.status === 401) || (error.message && error.message.includes('Failed to fetch'))) {
          dispatch(authActions.authError(error.error?.toString()));
        } else {
          dispatch(appStateActions.error(error))
        }*/
        })
        .finally(() => {
          setComponentPanelFetching(false);
        });
    };

    getGrants().then((newContentPanel) => {
      if (!newContentPanel) return;
      setDbContentPanel(newContentPanel);
      setComponentPanelFetching(false);
      console.log("Component props loaded..");
    });
  }, [createUserPanel, dispatch]);

  return (
    <>
      <Paper>
        <>
          <div className="heading">
            <div className="heading--four">User Panel</div>
          </div>
          <ZfDivider
            style={{ height: "1px", margin: "10px 0", display: "block" }}
          ></ZfDivider>
          <RenderModal
            action={onClickAction}
            setOnClickAction={setOnClickAction}
            setComponentPanelFetching={setComponentPanelFetching}
          />

          {componentPanelFetching && (
            <div
              id="spinnerContainer"
              data-testid="spinner"
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <ZfLoadingSpinner
                size="large"
                color="primary"
                type="infinite"
              ></ZfLoadingSpinner>
            </div>
          )}
          {!componentPanelFetching && (
            <div className="lgrid">{dbContentPanel}</div>
          )}
          <div
            className="mr-6 ml-6 mb-5 mt-32"
            style={{ display: componentPanelFetching ? "none" : "block" }}
            data-testid="job-execution-panel"
          >
            <JobExecutionPanel />
          </div>
        </>
      </Paper>
    </>
  );
};
