/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailTemplateDto } from "../models/EmailTemplateDto";
import type { EmailTemplateUpdateDto } from "../models/EmailTemplateUpdateDto";
import type { PagedModelEntityModelEmailPreviewDto } from "../models/PagedModelEntityModelEmailPreviewDto";
import type { SendEmailRequest } from "../models/SendEmailRequest";
import type { SendEmailResponse } from "../models/SendEmailResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class EmailControllerService {
  /**
   * @returns SendEmailResponse OK
   * @throws ApiError
   */
  public static sendEmail({
    requestBody,
  }: {
    requestBody: SendEmailRequest;
  }): CancelablePromise<SendEmailResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/email/send",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Returns a paged list of NostroOverviewDto entities
   * @returns EmailTemplateDto Paged list of NostroOverviewDto entities
   * @throws ApiError
   */
  public static getEmailTemplate({
    emailTypeEnum,
  }: {
    emailTypeEnum: "WITH_BALANCE" | "WITHOUT_BALANCE";
  }): CancelablePromise<EmailTemplateDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/email/template",
      query: {
        emailTypeEnum: emailTypeEnum,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Updates a specific EmailTemplate to find using the id sent
   * @returns EmailTemplateDto Record updated
   * @throws ApiError
   */
  public static updateEmailTemplate({
    requestBody,
  }: {
    requestBody: EmailTemplateUpdateDto;
  }): CancelablePromise<EmailTemplateDto> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/email/template",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
        404: `Record not found`,
      },
    });
  }

  /**
   * Returns a paged list of EmailPreviewDto entities
   * @returns PagedModelEntityModelEmailPreviewDto Paged list of EmailPreviewDto entities
   * @throws ApiError
   */
  public static getEmailPreview({
    page,
    size = 10,
    sort,
    search,
    upn,
    accountBalanceCount,
    relationshipManagerId,
    nostroCount,
    name,
    id,
    cobDate,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    upn?: string;
    accountBalanceCount?: number;
    relationshipManagerId?: number;
    nostroCount?: number;
    name?: string;
    id?: number;
    cobDate?: string;
  }): CancelablePromise<PagedModelEntityModelEmailPreviewDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/email/preview",
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        upn: upn,
        accountBalanceCount: accountBalanceCount,
        relationshipManagerId: relationshipManagerId,
        nostroCount: nostroCount,
        name: name,
        id: id,
        cobDate: cobDate,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }
}
