import {
  CellComponent,
  EmptyCallback,
} from "@ccx/zafire/dist/types/zf-table-type";

export const formatCHF = (
  value: number,
  showCurrency: boolean = false
): string => {
  return new Intl.NumberFormat("de-CH", {
    style: showCurrency ? "currency" : "decimal",
    currency: showCurrency ? "CHF" : undefined,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    signDisplay: "auto",
  }).format(value);
};

/* Clean and parse input value:
   - Remove all except numbers, dots, commas, and minus signs
   - Replace commas with dots for decimal
   - If multiple dots exist, keep only the last one
   - Preserve the first minus sign if present at the start
*/
export const parseInputImportValue = (value: string): number => {
  // First normalize commas to dots so we handle both formats
  const normalizedValue = value.replace(/,/g, ".");

  // Clean any non-numeric characters except dots and minus
  const cleanedValue = normalizedValue.replace(/[^0-9.-]/g, "");

  // Handle minus signs: keep only the first one if it's at the start
  const withSingleMinus = cleanedValue
    .replace(/^-+/, "-")
    .replace(/(?!^)-/g, "");

  // Split the string and handle the decimal part
  const parts = withSingleMinus.split(".");

  // Keep only the last part after dots
  const numberParts =
    parts.slice(0, -1).join("") +
    (parts.length > 0 ? "." + parts[parts.length - 1] : "");

  // Parse the final result
  return parseFloat(numberParts) || 0;
};

// Custom formatter for displaying CHF values
export const importCellFormatter = (cell: any) => {
  const value = cell.getValue();
  if (value === null || value === undefined) return "";
  return formatCHF(value);
};

// Handle input to restrict characters
export const importInputFunc = (event: InputEvent) => {
  const input = event.target as HTMLInputElement;
  const cursorPosition = input.selectionStart || 0;

  // Allow numbers, dots, commas, apostrophes, and minus signs
  const cleanedValue = input.value.replace(/[^0-9.,'-]/g, "");

  // Ensure minus sign only appears at the start and only once
  const finalValue = cleanedValue.replace(/^-+/, "-").replace(/(?!^)-/g, "");

  if (input.value !== finalValue) {
    input.value = finalValue;
    // Restore cursor position, adjusting if characters were removed
    const posDiff = input.value.length - finalValue.length;
    const newPos = Math.max(0, cursorPosition - posDiff);
    input.setSelectionRange(newPos, newPos);
  }
};

/**
 * Custom editor with import formatter:
 * - editor type text to allow custom input handling
 * - supports negative values
 */
export const customNumericEditor = function (
  cell: any,
  onRendered: any,
  success: any,
  cancel: any,
  editorParams: any
) {
  const editor = document.createElement("input");
  editor.setAttribute("type", "text");

  editor.style.padding = "3px";
  editor.style.width = "100%";
  editor.style.boxSizing = "border-box";
  editor.style.textAlign = "right";
  editor.style.direction = "ltr";

  // Get the initial value
  const initialValue = cell.getValue();

  // Set focus and initial value when editor is rendered
  onRendered(function () {
    editor.focus();
    // Display the initial value without currency symbol
    if (initialValue !== null && initialValue !== undefined) {
      editor.value = new Intl.NumberFormat("de-CH", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        signDisplay: "auto", // Changed from "negative" to "auto"
      })
        .format(initialValue)
        .replace("CHF", "")
        .trim();
    } else {
      editor.value = "";
    }
    editor.select();
  });

  const blurFunc = (event: any) => {
    const value = parseInputImportValue(event.target.value);
    if (!isNaN(value)) {
      success(value);
    } else {
      cancel();
    }
  };

  const changeFunc = (event: any) => {
    const columnField = cell.getColumn().getField();
    const value = parseInputImportValue(event.target.value);

    if (!isNaN(value)) {
      let entry = cell.getData();
      entry = { ...entry, [columnField]: value };
      return entry;
    }
  };

  // Handle keyboard events
  const keyEvents = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      blurFunc({ target: editor });
    } else if (event.key === "Escape") {
      cancel();
    } else if (
      !/[\d.,'-]/.test(event.key) &&
      !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(
        event.key
      )
    ) {
      // Prevent input of non-numeric characters except allowed special keys
      event.preventDefault();
    }
  };

  editor.addEventListener("blur", blurFunc);
  editor.addEventListener("change", changeFunc);
  editor.addEventListener("input", importInputFunc as EventListener);
  editor.addEventListener("keydown", keyEvents);

  return editor;
};

export const customChecboxFormatter = function (
  cell: CellComponent,
  formatterParams: {},
  onRendered: EmptyCallback
) {
  const editor = document.createElement("zf-checkbox");
  //editor.className = "p-3";

  // Get the initial value
  const initialValue = cell.getValue();

  // Set focus and initial value when editor is rendered
  onRendered(function () {
    //console.log("customCheckBoxEditor onRendered ", initialValue);
    //editor.focus();
    editor.checked = initialValue;
    //editor.select();
  });

  const changeFunc = (event: any) => {
    console.log("customCheckBoxEditor changeFunc ", event);
    cell.setValue(event.detail);
  };

  editor.addEventListener("checkboxCheck", changeFunc);

  return editor;
};

export const actionFormatter = (
  cell: CellComponent,
  formatterParams: {},
  onRendered: EmptyCallback
): string | HTMLZfCheckboxElement => {
  onRendered(function () {
    cell
      .getElement()
      .querySelectorAll("zf-checkbox")
      .forEach((item: any) =>
        item.addEventListener("checkboxCheck", () => {
          console.log("checkboxCheck", item);
          cell
            .getTable()
            .getRows()
            .forEach((row) => {
              row.update({
                [cell.getColumn().getField()]: item.checked,
              });
            });
        })
      );
  });
  return `<zf-checkbox></zf-checkbox>`;
};
