import { EntityModelAccountBalanceDto } from "../../openapi";
import { createSelectedItemSlice } from "../selected-item-slice";

const SelectedNostroAccountBalHistorySlice =
  createSelectedItemSlice<EntityModelAccountBalanceDto>(
    "selectedNostroAccBalanceHistory"
  );

export const {
  setSelectedItem: setSelectedNostroAccountBalHistorySlice,
  reset,
} = SelectedNostroAccountBalHistorySlice.actions;

export default SelectedNostroAccountBalHistorySlice.reducer;
