import { useEffect, useRef } from "react";
import { JobControllerService } from "../../openapi";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import { ZfTable } from "@ccx/zafire-react";

const JobExecutionPanel = () => {
  const dataTable = useRef<HTMLZfTableElement>(null);
  //const [fetchingJobInfo, setFetchingJobInfo] = useState(false);

  async function dataTableApiCall(url: any, config: any, params: any) {
    return authorizedCall(
      JobControllerService.getAllJobsRunInfo,
      {
        page: 1,
        size: 10,
      },
      true
    ).then((resp) => {
      return resp;
    });
  }

  useEffect(() => {
    let defaultOptions = zfTableDefaultOptions();

    const TABLE_COLUMNS = [
      //{ title: "ID", field: "id", maxWidth: 120 },
      { title: "Name", field: "jobEnum", sort: false },
      { title: "Last run at", field: "lastRunAt", maxWidth: 200 },
      { title: "Last run status", field: "lastRunStatus", maxWidth: 200 },
      { title: "Last run user", field: "lastRunUser", maxWidth: 200 },
      { title: "Last completed run at", field: "lastOkRunAt", maxWidth: 200 },
      {
        title: "Last completed run user",
        field: "lastOkRunUser",
        maxWidth: 180,
      },
    ];

    const actionColumns: [] = [];

    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      paginationCounter: undefined,
      headerSortElement: "",
      paginationSize: 10,
      selectable: 1,
      ajaxRequestFunc: dataTableApiCall,
    };

    //dataTable.current.querySelector('.tabulator-footer').style.display = "none"
  }, []);

  return (
    <div className="p-3 border">
      <div className="heading">
        <div className="heading--four">Job Run Info</div>
      </div>
      <ZfTable
        ref={dataTable}
        onTableBuilt={(event) =>
          tableBuiltHandler(
            event,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true
          )
        }
        // onTableRowSelectionChanged={rowSelectionChanged}
        className="mt-5"
        data-testid="jobs-data-table"
      ></ZfTable>
    </div>
  );
};

export default JobExecutionPanel;
