import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  EntityModelNostroCommentStatusDto,
  NostroCommentDto,
  NostroCommentStatusControllerService,
  NostroControllerService,
  PagedModelEntityModelNostroCommentStatusDto,
} from "../../openapi";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import {
  ZfCheckbox,
  ZfInputField,
  ZfSelectField,
  ZfSelectOption,
  ZfTextareaField,
  ZfTextButton,
} from "@ccx/zafire-react";
import { AppError, appStateActions } from "../../store/appstate-slice";
import { useAppDispatch } from "../../store/store";
import Spinner from "../common/Spinner";

interface FormInput {
  value: any;
  textError?: string;
  state?: "invalid" | "readonly";
}

interface FormData {
  id: FormInput;
  createUser: FormInput;
  createTs: FormInput;
  nostroCommentStatusId: FormInput;
  showOnReport: FormInput;
  link: FormInput;
  comment: FormInput;
  [key: string]: FormInput["value"];
}

function initFormData(): FormData {
  return {
    id: { value: 0 },
    createUser: { value: "" },
    createTs: { value: "" },
    nostroCommentStatusId: { value: 0 },
    showOnReport: { value: false },
    link: { value: "" },
    comment: { value: "" },
  };
}

interface NostroCommentDtoIdx extends NostroCommentDto {
  [key: string]: any;
}

export const AccountCommentEdit = () => {
  const navigate = useNavigate();

  const { accId, commId } = useParams();

  const [accCommFetching, setAccCommFetching] = useState(false);

  const [formData, setFormData] = useState<FormData>(initFormData);

  const dispatch = useAppDispatch();

  const [allNostroCommentStatusComboBox, setAllNostroCommentStatusComboBox] =
    useState<Array<EntityModelNostroCommentStatusDto>>();

  useEffect(() => {
    function getAllNostroCommentStatusComboBox(): Promise<
      Array<EntityModelNostroCommentStatusDto>
    > {
      const apiParams = {
        page: 0,
        size: 1000,
      };

      return authorizedCall(
        NostroCommentStatusControllerService.getAllNostroCommentStatus,
        apiParams,
        true
      ).then((dto: PagedModelEntityModelNostroCommentStatusDto) => {
        console.log(dto);
        if (dto._embedded === undefined) {
          dispatch(
            appStateActions.error(
              "Undefined Nostro Comment Status combobox values."
            )
          );
          return new Array<EntityModelNostroCommentStatusDto>();
        }
        return dto._embedded!.entities!;
      });
    }

    setAccCommFetching(true);
    getAllNostroCommentStatusComboBox()
      .then((list) => {
        setAllNostroCommentStatusComboBox(list);
      })
      .catch((error: any) => {
        console.log("error in getAllNostroCommentStatus", error);
      })
      .finally(() => {
        setAccCommFetching(false);
      });

    if (commId === undefined || isNaN(Number(commId))) {
      return;
    }

    authorizedCall(
      NostroControllerService.getNostroCommentDetail,
      { nostroPathId: Number(accId), nostroCommentPathId: Number(commId) },
      true
    )
      .then((dto: NostroCommentDtoIdx) => {
        console.log("getNostroDetail response", dto);
        const newFormData: FormData = initFormData();
        Object.keys(dto).forEach((key: string) => {
          if (newFormData[key] !== undefined) newFormData[key].value = dto[key];
        });
        setFormData(newFormData);
      })
      .catch((error: any) => {
        console.log("error in getNostroDetail", error);
      })
      .finally(() => {
        setAccCommFetching(false);
      });
  }, [commId, accId, dispatch]);

  function updateformDataImpl(field: string, subfield: string, value: any) {
    setFormData((prev: FormData) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [subfield]: value,
      },
    }));
  }

  function updateformDataValue(event: any) {
    const { name, value } = event.target;

    let fixedValue = value;
    if (event.currentTarget.type === "number") {
      fixedValue = Number(value.replaceAll(",", ""));
    }

    updateformDataImpl(name, "value", fixedValue);
  }

  function focusOnInputHandler(event: any) {
    const { name } = event.target;

    updateformDataImpl(name, "textError", undefined);
    updateformDataImpl(name, "state", undefined);
  }

  function isNotEmpty(value: string) {
    if (typeof value === "number") return true;
    return value.trim() !== "";
  }

  function validate(): boolean {
    let valid: boolean = true;

    console.log(
      'validation: nostroCommentStatusId.value="' +
        formData.nostroCommentStatusId.value +
        '" (type ' +
        typeof formData.nostroCommentStatusId.value +
        ")"
    );

    if (
      !formData.nostroCommentStatusId ||
      formData.nostroCommentStatusId.value === 0 ||
      (formData.nostroCommentStatusId &&
        !isNotEmpty(formData.nostroCommentStatusId.value))
    ) {
      updateformDataImpl(
        "nostroCommentStatusId",
        "textError",
        "Required field"
      );
      updateformDataImpl("nostroCommentStatusId", "state", "invalid");
      valid = false;
    }

    if (
      !formData.comment ||
      formData.comment.value === 0 ||
      (formData.comment && !isNotEmpty(formData.comment.value))
    ) {
      updateformDataImpl("comment", "textError", "Required field");
      updateformDataImpl("comment", "state", "invalid");
      valid = false;
    }

    return valid;
  }

  function submitHandler() {
    if (!validate()) {
      return;
    }

    setAccCommFetching(true);
    const promise =
      commId === undefined || isNaN(Number(commId))
        ? authorizedCall(NostroControllerService.createNostroComment, {
            nostroPathId: Number(accId),
            requestBody: {
              nostroId: Number(accId),
              nostroCommentStatus: Number(
                formData?.nostroCommentStatusId.value
              ),
              comment: String(formData?.comment.value),
              link: String(formData?.link.value),
              showOnReport: formData?.showOnReport.value,
            },
          })
        : authorizedCall(NostroControllerService.updateNostroComment, {
            nostroPathId: Number(accId),
            nostroCommentPathId: Number(commId),
            requestBody: {
              nostroCommentStatus: Number(
                formData?.nostroCommentStatusId.value
              ),
              comment: String(formData?.comment.value),
              link: String(formData?.link.value),
              showOnReport: formData?.showOnReport.value,
            },
          });

    promise
      .then(() => {
        navigate(`/acc-view/${accId}`);
      })
      .catch((error) => {
        const err: AppError = {
          code: error.status,
          message: error.error?.toString(),
        };
        dispatch(appStateActions.error(err));
      })
      .finally(() => {
        setAccCommFetching(false);
      });
  }

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        { name: "View Nostro", route: "/acc-view" },
        { name: "Nostro Detail", route: `/acc-view/${accId}` },
        { name: "Comment" },
      ]}
    >
      <div className="content p-4 bg-white">
        <div className="flex flex-row">
          <div className="basis-1/2">
            <p
              className="heading--three text-xl"
              data-testid="acc-cat-pagetitle"
            >
              Comment
            </p>
          </div>
          <div className="basis-1/2 text-right">
            <ZfTextButton
              hierarchy="tertiary"
              onClick={() => navigate(`/acc-view/${accId}`)}
              size="small"
            >
              Cancel
            </ZfTextButton>
            <ZfTextButton
              onClick={submitHandler}
              data-testid="acc-comm-save-btn"
              size="small"
            >
              Save
            </ZfTextButton>
          </div>
        </div>

        <Spinner fetching={accCommFetching} />

        {!accCommFetching && (
          <>
            <div className="mt-4" data-testid="comment-edit">
              <div className="grid grid-cols-4 gap-4">
                {commId && !isNaN(Number(commId)) && (
                  <>
                    <ZfInputField
                      label="Username"
                      value={String(formData?.createUser.value)}
                      placeholder=""
                      state="readonly"
                      data-testid="acc-comm-create-user"
                    ></ZfInputField>

                    <ZfInputField
                      label="Date"
                      value={String(formData?.createTs.value)}
                      placeholder=""
                      state="readonly"
                      data-testid="acc-comm-create-ts"
                    ></ZfInputField>
                  </>
                )}

                <ZfSelectField
                  name="nostroCommentStatusId"
                  label="Status"
                  value={String(formData?.nostroCommentStatusId.value)}
                  errorText={formData.nostroCommentStatusId.textError}
                  state={formData.nostroCommentStatusId.state}
                  onFocus={focusOnInputHandler}
                  onSelectFieldChange={updateformDataValue}
                  mandatory
                  data-testid="acc-comm-status"
                  //helperText="These cars are for sale"
                >
                  {allNostroCommentStatusComboBox?.map((st) => (
                    <ZfSelectOption value={String(st.id)} key={st.id}>
                      {st.text}
                    </ZfSelectOption>
                  ))}
                </ZfSelectField>
                <ZfCheckbox
                  label="Show on report"
                  checked={formData?.showOnReport.value}
                  onCheckboxCheck={(event) => {
                    formData.showOnReport.value = event.detail;
                  }}
                  data-testid="acc-comm-show-on-report"
                ></ZfCheckbox>
              </div>
              <>
                <div className="pb-3">
                  <ZfTextareaField
                    name="link"
                    data-testid="acc-comment-link"
                    value={String(formData?.link.value)}
                    errorText={formData.link.textError}
                    state={formData.link.state}
                    onFocus={focusOnInputHandler}
                    onBlur={updateformDataValue}
                    label="File Link"
                    //helperText="Helper text."
                    className="mt-3"
                    //mandatory
                    maxlength={500}
                  ></ZfTextareaField>
                </div>

                <div className="pb-3">
                  <ZfTextareaField
                    name="comment"
                    data-testid="acc-comment"
                    value={String(formData?.comment.value)}
                    errorText={formData.comment.textError}
                    state={formData.comment.state}
                    onFocus={focusOnInputHandler}
                    onBlur={updateformDataValue}
                    label="Comment"
                    className="mt-3"
                    mandatory
                    maxlength={500}
                  ></ZfTextareaField>
                </div>
              </>
            </div>
          </>
        )}
      </div>
    </AuthenticatedLayout>
  );
};
