import {
  ZfIconButton,
  ZfLoadingSpinner,
  ZfSearchField,
  ZfTable,
  ZfTextButton,
} from "@ccx/zafire-react";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import { useCallback, useEffect, useRef, useState } from "react";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  EntityModelJobLogDto,
  JobControllerService,
  NostroControllerService,
  PagedModelEntityModelJobLogDto,
} from "../../openapi";
import { useNavigate } from "react-router-dom";
import { StoreSlices, useAppDispatch, useAppSelector } from "../../store/store";
import {
  setSelectedNostroItem,
  reset,
} from "../../store/select-item-slice-impl/selected-item-slice-nostro";
import RenderModal from "./RenderModal";
import { importCellFormatter } from "../common/ZfTable/zftable-cell-editors";

export const AccountView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [error, setError] = useState(false);
  const [onClickAction, setOnClickAction] = useState<
    "import-nostro" | "archive-nostro"
  >();

  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);
  const dataTable = useRef<HTMLZfTableElement>(null);

  const selected = useAppSelector(
    (state) => state.selectedNostroItemSlice.selected
  );

  const [accFetching, setAccFetching] = useState<boolean>(false);

  const [lastImportAcc, setLastImportAcc] = useState<EntityModelJobLogDto>();

  const dataTableApiCall = useCallback(
    async (url: any, config: any, params: any) => {
      setAccFetching(true);

      const sortArray: Array<string> =
        params.sort.length > 0
          ? [`${params.sort[0].field},${params.sort[0].dir}`]
          : [];

      let apiParams: Parameters<
        typeof NostroControllerService.getAllNostro
      >[0] = {
        page: params.page,
        size: params.size,
        sort: sortArray,
      };

      if (searchFilter.current?.value) {
        apiParams = { ...apiParams, search: searchFilter.current?.value };
      }

      return authorizedCall(
        NostroControllerService.getAllNostro,
        apiParams,
        true
      )
        .then((resp) => {
          return resp;
        })
        .catch((error) => {})
        .finally(() => {
          setAccFetching(false);
        });
    },
    []
  );

  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      {
        formatter: "zfRadioRowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 50,
      },
      { title: "ID", field: "id", maxWidth: 70 },
      { title: "Number", field: "number" /*, headerFilter: 'input'*/ },
      { title: "Name", field: "name" },
      { title: "Category", field: "code" },
      { title: "Risk<br/>Compl", field: "riskComplianceCode" },
      { title: "Risk<br/>Fraud", field: "riskFraudCode" },
      { title: "RM<br/>UserID", field: "upn" },
      {
        title: "Status",
        field: "status",
        formatter: (cell: any) => formatStatusCell(cell.getValue()),
      },
      {
        title: "KSTA<br/>CHF",
        field: "busaldoChfBr",
        formatter: (cell: any) => formatCell(cell, true),
        hozAlign: "center",
      },
      {
        title: "KSTA<br/>Ccy",
        field: "kstaCcyBr",
        formatter: (cell: any) => formatCell(cell, true),
        hozAlign: "center",
      },
      {
        title: "Bu Saldo<br/>CHF",
        field: "busaldoChfBr",
        formatter: (cell: any) => formatCell(cell, true),
        hozAlign: "center",
      },
      {
        title: "Bu Saldo<br/>Ccy",
        field: "busaldoCcyBr",
        formatter: (cell: any) => formatCell(cell, true),
        hozAlign: "center",
      },
      {
        title: "Account<br/>Limit<br/>Count",
        field: "hasLimits",
        hozAlign: "center",
      },
      {
        title: "Comment<br/>Count",
        field: "commentCount",
        hozAlign: "center",
      },
    ];

    const actionColumns: [] = [];

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
      selectable: 1,
    };

    getLastImportAcc();
  }, [dataTableApiCall]);

  function refreshTable(event: any) {
    dispatch(reset());
    dataTable.current!.options = {
      ...dataTable.current!.options,
    };
  }

  const tableBuiltHandlerCallback = useCallback(
    (event: any) =>
      tableBuiltHandler(
        event,
        setAccFetching,
        undefined,
        StoreSlices.selectedNostroItemSlice,
        setSelectedNostroItem,
        undefined
      ),
    []
  );

  function getLastImportAcc() {
    setAccFetching(true);
    authorizedCall(
      JobControllerService.getLastJobRun,
      { jobName: "IMPORT_NOS" },
      true
    )
      .then((resp: PagedModelEntityModelJobLogDto) => {
        console.log(resp);
        if (resp._embedded?.entities)
          setLastImportAcc(resp._embedded.entities[0]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        //setAccFetching(false);
      });
  }

  function formatCell(cell: any, skipImportFormat?: boolean) {
    const formattedImport = skipImportFormat
      ? cell.getValue()
      : importCellFormatter(cell);
    if (cell.getValue() > 0)
      return `<zf-pill type="solid" color="red">${formattedImport}</zf-pill>`;

    return formattedImport;
  }

  function formatStatusCell(cellValue: string) {
    const color =
      {
        ok: "green",
        arc: "green",
        chk: "yellow",
        del: "red",
        new: "blue",
      }[cellValue] || "neutral";

    return `<zf-pill type="solid" color=${color}>${cellValue}</zf-pill>`;
  }

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        { name: "View Nostro" },
      ]}
    >
      <div className="content p-4 bg-white">
        <div style={{ borderBottom: "1px solid #ddd" }}>
          <div className="flex flex-row pb-6">
            <div className="basis-1/2">
              <p
                className="heading--two text-lg"
                data-testid="view-relationship-manager-pagetitle"
              >
                View Nostro
              </p>
            </div>
            <div className="basis-1/4">
              <ZfSearchField
                placeholder="Search ..."
                onInputChange={(e) => {
                  refreshTable(e);
                }}
                ref={searchFilter}
              ></ZfSearchField>
            </div>

            <div className="basis-1/2 flex flex-row-reverse">
              <ZfTextButton
                disabled={selected === null}
                onClick={() => navigate(`/acc-view/${selected?.id}`)}
                hierarchy="primary"
                className="mr-1"
                size="small"
              >
                Edit
              </ZfTextButton>
              <ZfTextButton
                hierarchy="secondary"
                disabled={accFetching === true}
                onClick={() => setOnClickAction("import-nostro")}
                className="mr-2"
                data-testid="update-all-acc-button"
                size="small"
              >
                Update All
              </ZfTextButton>
              <ZfTextButton
                hierarchy="secondary"
                disabled={accFetching === true}
                onClick={() => setOnClickAction("archive-nostro")}
                className="mr-2"
                size="small"
              >
                Archive deleted
              </ZfTextButton>
              <ZfIconButton
                hierarchy="secondary"
                icon="arrow_back"
                className="mr-2"
                onClick={() => navigate(`/`)}
                size="small"
              ></ZfIconButton>
            </div>
          </div>
        </div>
        <div className="mt-4 mb-3 text-sm">
          <label className="font-semibold">Last import by:</label>
          <label className="font-light pl-2">{lastImportAcc?.createUser}</label>
          <label className="font-semibold ml-2">at:</label>
          <label className="font-light pl-2">{lastImportAcc?.createTs}</label>
          <label className="font-semibold ml-2">with status:</label>
          <label className="font-light pl-2">{lastImportAcc?.status}</label>
          {lastImportAcc?.message && (
            <label className="font-light pl-2">
              {" "}
              - {lastImportAcc?.message}
            </label>
          )}
        </div>
        {error && <p className="error">Error in loading data</p>}
        <RenderModal
          action={onClickAction}
          setOnClickAction={setOnClickAction}
          setComponentPanelFetching={setAccFetching}
        />
        {accFetching && (
          <ZfLoadingSpinner
            size="large"
            color="primary"
            type="infinite"
            style={{
              position: "absolute",
              left: "50%",
              right: "50%",
              zIndex: "100",
              top: "35%",
            }}
            data-testid="spinner"
          ></ZfLoadingSpinner>
        )}

        <ZfTable
          ref={dataTable}
          onTableBuilt={(event) => tableBuiltHandlerCallback(event)}
          //onTableRowSelectionChanged={rowSelectionChanged}
          className="mt-5"
          data-testid="vrm-data-table"
          style={{
            pointerEvents: accFetching && "none",
            opacity: accFetching && 0.7,
          }}
        ></ZfTable>
      </div>
    </AuthenticatedLayout>
  );
};
