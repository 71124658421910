import { FC, PropsWithChildren } from "react";
import "@ccx/foundations/variables.css";
import "@ccx/foundations/font-faces.css";
import {
  defineCustomElements,
  ZfAppContainer,
  ZfWebComponentsConfig,
} from "@ccx/zafire-react";

const ZfConfig: FC<PropsWithChildren> = (props) => {
  defineCustomElements();

  return (
    <ZfAppContainer>
      <ZfWebComponentsConfig localeCode="it-CH">
        {props.children}
      </ZfWebComponentsConfig>
    </ZfAppContainer>
  );
};

export default ZfConfig;
