import { EntityModelAccountBalanceDto } from "../../openapi";
import { createSelectedItemSlice } from "../selected-item-slice";

const SelectedNostroAccountBalanceSlice =
  createSelectedItemSlice<EntityModelAccountBalanceDto>(
    "selectedNostroAccountBalance"
  );

export const { setSelectedItem: setSelectedNostroAccountBalanceSlice, reset } =
  SelectedNostroAccountBalanceSlice.actions;

export default SelectedNostroAccountBalanceSlice.reducer;
